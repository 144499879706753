import { AddressType } from '@src/shared';
import { Profile } from '@src/entities/agent-contract';
import { AgentBranch } from '@src/entities/dictionaries';

export const agentEmploymentDetailsDefaultBranchCode = '17714021';

interface GetDefaultAgentEmploymentDetailsBranchCode {
  profile?: Profile;
  agentBranchDictionary?: AgentBranch[];
}

export const getDefaultAgentEmploymentDetailsBranchCode = ({
  profile,
  agentBranchDictionary,
}: GetDefaultAgentEmploymentDetailsBranchCode) => {
  // Handle Imported case.
  if (profile?.isImported) {
    return profile?.employmentDetails?.branchCode;
    // Alternative scenario
  }
  // Get user accommodation address
  const userAddress = profile?.addressesAgents?.find(
    (address) => address?.addressType === AddressType.ACCOMMODATION
  );

  return agentBranchDictionary?.reduce((code, currBranch) => {
    // Try to find branch in dictionary by fiasCode and regionCode
    if (currBranch.fiasCode === userAddress?.regionCode) {
      code = currBranch.code;
    } else if (
      !code &&
      currBranch?.code === agentEmploymentDetailsDefaultBranchCode
    ) {
      // If branch is not found than return default branch
      code = currBranch.code;
    }
    return code;
  }, '');
};
