import { useEffect, useState } from 'react';

import { getAgentBranchOptions } from '@src/entities/dictionaries';
import { getDefaultAgentEmploymentDetailsBranchCode } from './get-default-agent-employment-details-branch-code';
import { useStores } from '@src/shared';

import type { Option } from '@pulse-web-ui/autocomplete-select';

export const useGetAgentEmploymentDetailsBranchOptions = () => {
  const {
    MainStore: {
      agentContractStore: { profile },
      dictionariesStore: {
        agentBranchDictionary,
        isAgentBranchDictionaryLoaded,
      },
    },
  } = useStores();
  const [options, setOptions] = useState<{
    branchOptions: Option[];
    defaultBranchCode?: string;
  }>({
    branchOptions: [],
  });

  useEffect(() => {
    if (isAgentBranchDictionaryLoaded) {
      const branchOptions = getAgentBranchOptions(agentBranchDictionary);

      const defaultBranchCode = getDefaultAgentEmploymentDetailsBranchCode({
        profile,
        agentBranchDictionary,
      });

      if (options.branchOptions.length === 0) {
        setOptions({
          branchOptions,
          defaultBranchCode,
        });
      }
    }
  }, [
    isAgentBranchDictionaryLoaded,
    agentBranchDictionary,
    profile,
    options.branchOptions.length,
  ]);

  return options;
};
